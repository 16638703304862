/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as STAR } from '../../assets/good_way_star_test.svg';
import StarLogo from '../../assets/good_way_star_test.svg';

export const rotateAnimation = keyframes`
    0% {transform: opacity: 0.7 scale(0.4); opacity: 0.5}
    50% {transform: scale(2.2); opacity: 0.01}
    100% {transform: 0.7 scale(0.4); opacity: 0.5}
    `;

const Star = ({ style, vW, vH, duration }) => {
  const AnimatedStarIcon = styled.div`
    animation: ${rotateAnimation} ${duration}s linear infinite;
    height: 4vh;
    width: 3vw;
  `;
  return (
    <>
      <div
        style={{
          ...style,

          transform: `translate(${vW}, ${vH})`,
        }}
      >
        <AnimatedStarIcon>
          <img src={StarLogo} alt="*" />
        </AnimatedStarIcon>
      </div>
    </>
  );
};

export default Star;
