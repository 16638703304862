/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';
import Card from '../card/card.jsx';
import Sky from '../sky/sky.jsx';

const Body = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #0090ff 0%, #ffffff 95%);
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Home = () => {
  return (
    <Body>
      <div
        style={{
          width: '100vw',
          height: '40vh',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      >
        <Sky />
      </div>
      <div
        style={{
          width: '100vw',
          height: '30vh',
          display: 'flex',
          justifyContent: 'space-evenly',
          transform: 'translateY(10vh)',
          padding: '10rem',
        }}
      >
        {/* <Card />
        <Card />
        <Card /> */}
      </div>
    </Body>
  );
};

export default Home;
