/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Star from './star.jsx';

const Sky = ({ style }) => {
  return (
    <>
      <div style={{ ...style }}>
        <Star vW="2vw" vH="5vh" duration={15} />
        <Star vW="8vw" vH="33vh" duration={12} />
        <Star vW="2vw" vH="15vh" duration={25} />
        <Star vW="5vw" vH="5vh" duration={15} />
        <Star vW="7vw" vH="33vh" duration={12} />
        <Star vW="2vw" vH="15vh" duration={25} />
        <Star vW="15vw" vH="-12vh" duration={11} />
        <Star vW="17vw" vH="5vh" duration={12} />
        <Star vW="28vw" vH="4vh" duration={18} />
        <Star vW="27vw" vH="-20vh" duration={12} />
        <Star vW="32vw" vH="-38vh" duration={16} />
        <Star vW="35vw" vH="-34vh" duration={15} />
        <Star vW="40vw" vH="-30vh" duration={19} />
        <Star vW="45vw" vH="-45vh" duration={12} />
        <Star vW="49vw" vH="19vh" duration={20} />
        <Star vW="55vw" vH="-7vh" duration={14} />
        <Star vW="57vw" vH="-80vh" duration={25} />
        <Star vW="59vw" vH="55vh" duration={17} />
        <Star vW="80vw" vH="-90vh" duration={15} />
        <Star vW="64vw" vH="-40vh" duration={17} />
        <Star vW="90vw" vH="-50vh" duration={18} />
        <Star vW="85vw" vH="-29vh" duration={19} />
        <Star vW="74vw" vH="-25vh" duration={21} />
        <Star vW="77vw" vH="-90vh" duration={22} />
        <Star vW="80vw" vH="-73vh" duration={25} />
        <Star vW="80vw" vH="-62vh" duration={19} />
        <Star vW="7vw" vH="9vh" duration={22} />
        <Star vW="8vw" vH="5vh" duration={20} />
        <Star vW="15vw" vH="-68vh" duration={17} />
        <Star vW="70vw" vH="-53vh" duration={25} />
        <Star vW="62vw" vH="-90vh" duration={19} />
        <Star vW="75vw" vH="-110vh" duration={22} />
        <Star vW="90vw" vH="-120vh" duration={20} />
        <Star vW="65vw" vH="-180vh" duration={17} />
      </div>
    </>
  );
};

export default Sky;
